<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入客户编码/客户名称"
          v-model.trim="listQuery.customerName"
          style="width: 200px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right: 10px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form
            style="width: 550px"
            label-width="90px"
            size="small"
            inline="true"
            class="style_form"
          >
            <el-form-item label="录入时间：">
              <el-date-picker
                style="width: 150px"
                v-model="listQuery.createStartDate"
                type="date"
                placeholder="选择日期"
                clearable
                value-format="YYYY-MM-DD"
              />
              &nbsp;-&nbsp;
              <el-date-picker
                style="width: 150px"
                v-model="listQuery.createEndDate"
                type="date"
                placeholder="选择日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="纳税人资格：">
              <selecttaxtype
                v-model:type="listQuery.taxType"
                style="width: 147px"
              ></selecttaxtype>
            </el-form-item>
            <el-form-item label="主办会计：">
              <select-common
                placeholder="请选择主办会计"
                v-model:modelValue="listQuery.accountingId"
                :options="userOptions"
                valueKey="id"
                labelKey="CnName"
              />
            </el-form-item>
            <el-form-item label="服务类型：">
              <select-common
                placeholder="请选择服务类型"
                v-model:modelValue="listQuery.serverType"
                :options="serviceOptions"
              />
            </el-form-item>
            <el-form-item label="客户来源：">
              <select-common
                placeholder="请选择客户来源"
                v-model:modelValue="listQuery.customerSource"
                :options="resourceOptions"
              />
            </el-form-item>
            <el-form-item label="录入人：">
              <select-common
                placeholder="请选择录入人"
                v-model:modelValue="listQuery.userId"
                :options="userOptions"
                valueKey="id"
                labelKey="CnName"
              />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button size="small" type="primary" icon="Plus" @click="add"
          >新增</el-button
        >
        <el-button size="small" type="success" @click="allot" icon="User"
          >分配</el-button
        >
        <el-dropdown trigger="click">
          <el-button type="primary" size="small" class="ml_12">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="allotEdit">批量修改</el-dropdown-item>
              <el-dropdown-item @click="stopCustomer"
                >停止服务</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table
        v-loading="loading"
        :data="list"
        :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }"
        height="auto"
        style="width: 100%"
        border
        stripe
        auto-resize
        size="mini"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{ resizable: true }"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column
          align="center"
          field="sortColumn"
          type="checkbox"
          width="50"
        >
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="80">
          <template #default="scope">
            <span>{{ scope.row.customerNum }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerName" title="客户名称" minWidth="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <div class="name_left">
                <span>{{ scope.row.customerName }}</span>
              </div>
              <el-tooltip content="复制" placement="top" effect="dark">
                <i
                  class="iconfont icon-fuzhi1"
                  style="color: var(--themeColor, #17a2b8); cursor: pointer"
                  @click="$copyComName(scope.row.customerName)"
                >
                </i>
              </el-tooltip>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="accountingName" title="主办会计" minWidth="90">
        </vxe-column>
        <vxe-column field="taxType" title="纳税人类型" minWidth="90">
          <template #default="scope">
            <span v-if="scope.row.taxType == '1'">小规模纳税人</span>
            <span v-else-if="scope.row.taxType == '2'">一般纳税人</span>
            <span v-else></span>
          </template>
        </vxe-column>
        <vxe-column field="accountDate" title="客户来源" minWidth="80">
          <template #default="scope">
            <span>{{ getLabelByValue(scope.row.customerSource) }}</span>
          </template>
        </vxe-column>
        <vxe-column field="serverType" title="服务类型" minWidth="80"> </vxe-column>
        <vxe-column field="serviceMode" title="服务方式" minWidth="80"> </vxe-column>

        <!-- <vxe-column field="accountSystem" title="会计制度" minWidth="80"> </vxe-column> -->
        <vxe-column field="hybzName" title="国家标准行业" minWidth="100"> </vxe-column>
        <!-- <vxe-column field="accountDate" title="建账日期" minWidth="80"> </vxe-column>
        <vxe-column field="stockDate" title="库存启用日期" minWidth="100"> </vxe-column>
        <vxe-column field="assetsDate" title="资产启用日期" minWidth="100"> </vxe-column>
        <vxe-column field="inventoryName" title="图片处理方式" minWidth="100"> </vxe-column> -->

        <vxe-column field="cnName" title="录入人" minWidth="80"> </vxe-column>
        <vxe-column field="createdAt" title="录入日期" width="90">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}") }}</span>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="135">
          <template #default="scope">
            <el-button
              link
              size="small"
              @click="handleEdit(scope.row)"
              icon="Edit"
              >编辑</el-button
            >
            <el-button
              link
              size="small"
              @click="del(scope.row)"
              icon="Delete"
              type="danger"
              >删除</el-button
            >
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
  <!-- 新增编辑 -->
  <clientForm ref="clientFormRef" @success="getList" />
  <!-- 批量修改 -->
  <clientBatchEdit
    ref="clientBatchEditRef"
    :userOptions="userOptions"
    @success="getList"
  />
  <!-- 分配 -->
  <clientAllot ref="clientAllotRef" :userOptions="userOptions" />
  <!-- 停止服务 -->
  <clientStop ref="clientStopRef" @success="getList" />
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, onActivated } from "vue";
import { ElMessage } from "element-plus";
import { CUSTOMER_STATUS } from "@/utils/commonData";
import { customerList, delCustomer } from "@/api/newCrm";
import { findPeople } from "@/api/base";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import clientForm from "./components/clientForm.vue";
import clientBatchEdit from "./components/clientBatchEdit.vue";
import clientAllot from "./components/clientAllot.vue";
import clientStop from "./components/clientStop.vue";

const { proxy } = getCurrentInstance();
const list = ref([]);
const total = ref(0);
const contentStyleObj = ref({});
const loading = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
  customerType: "2",
});
const serviceOptions = ref([
  { label: "全部", value: "" },
  { label: "代账", value: "代账" },
  { label: "增值", value: "增值" },
]);
const resourceOptions = ref(CUSTOMER_STATUS);
const clientFormRef = ref();
const sels = ref([]);
const userOptions = ref([]);
const clientBatchEditRef = ref();
const clientAllotRef = ref();
const clientStopRef = ref();
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list;
  });
  getList();
});
onActivated(() => {
  getList()
})
const getList = () => {
  sels.value = [];
  loading.value = true;
  customerList({
    ...listQuery.value,
    taxType: listQuery.value.taxType ? listQuery.value.taxType : undefined,
    accountingId: listQuery.value.accountingId ? listQuery.value.accountingId : undefined,
    serverType: listQuery.value.serverType ? listQuery.value.serverType : undefined,
    customerSource: listQuery.value.customerSource ? listQuery.value.customerSource : undefined,
    userId: listQuery.value.userId ? listQuery.value.userId : undefined,
  }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};

const getLabelByValue = (value) => {
  if (!value) return ''
  const status = CUSTOMER_STATUS.find((item) => item.value === value);
  return status?.label || ''
};
const add = () => {
  clientFormRef.value.init();
};
//编辑
const handleEdit = (row) => {
  clientFormRef.value.init(row);
};
const handleSelectionChange = (data) => {
  sels.value = data.records;
};
const handleSelectionChangeAll = (data) => {
  sels.value = data.records;
};
//分配
const allot = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  clientAllotRef.value.init(sels.value);
};
//停止服务
const stopCustomer = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  clientStopRef.value.init(sels.value);
};
//批量修改
const allotEdit = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  clientBatchEditRef.value.init(sels.value);
};
//删除
const del = (row) => {
  proxy
    .$confirm("确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      delCustomer({ ids: [row.id] }).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("删除成功");
          getList();
        }
      });
    });
};
const cancel = () => {
  let originLimit = listQuery.value.limit;
  listQuery.value = {
    page: 1,
    limit: originLimit,
    userId: null,
    customerType: "2",
  };
  getList()
};
</script>
<script>
export default {
  name: "clientMine",
};
</script>
<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-checkbox) {
  margin-right: 15px;
}
.style_form {
  .el-input {
    width: 147px;
  }
  .el-select {
    width: 147px;
    margin: 0;
  }
}
.filter-item {
  margin-right: 15px !important;
}
:deep(.el-dropdown-menu__item) {
  display: block;
}
.name_left {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
