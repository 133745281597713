<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="批量修改"
    width="400px"
    destroy-on-close
  >
    <el-form :model="form">
      <el-form-item label="主办会计">
        <select-common
          placeholder="请选择主办会计"
          v-model:modelValue="form.accountId"
          :options="props.userOptions"
          valueKey="id"
          labelKey="CnName"
        />
      </el-form-item>
      <el-form-item label="客户来源">
        <select-common
          placeholder="请选择客户来源"
          v-model:modelValue="form.customerSource"
          :options="resourceOptions"
        />
      </el-form-item>
      <el-form-item label="服务方式">
        <select-common
          placeholder="请选择服务方式"
          v-model:modelValue="form.serviceMode"
          :options="serviceOptions"
        />
      </el-form-item>
      <el-form-item label="国家标准行业" prop="hybzId">
        <selectindustry v-model="form.hybzId" style="width: 175px;"></selectindustry>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="editSure"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { CUSTOMER_STATUS, SERVICE_OPTION } from "@/utils/commonData";
import { totalUpdateCustomer } from "@/api/newCrm";
import { ElMessage } from "element-plus";
import selectindustry from "../../clue/components/selectIndustry.vue";

const emit = defineEmits();
const props = defineProps({
  userOptions: {
    type: Array,
  },
});
const resourceOptions = CUSTOMER_STATUS.filter((item) => item.label !== "全部");
const serviceOptions = ref(SERVICE_OPTION);
const dialogFormVisible = ref(false);
const form = ref({
  ids: [],
  accountId: null,
  customerSource: null,
  serviceMode: "",
});
const init = (item) => {
  reset();
  form.value.ids = item.map((v) => v.id);
  dialogFormVisible.value = true;
};
const editSure = () => {
  totalUpdateCustomer(form.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("操作成功");
      dialogFormVisible.value = false;
      emit("success");
    }
  });
};
const reset = () => {
  form.value = {
    ids: [],
    accountId: null,
    customerSource: null,
    serviceMode: "",
  };
};
defineExpose({
  init,
});
</script>
